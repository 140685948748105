import React, { useState, useRef,useEffect } from 'react';
import { makeStyles, Paper, Box, IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DatePicker } from "jalali-react-datepicker";
import {green, grey, indigo} from '@material-ui/core/colors';
import {SERVER_URL,getSessionRef} from '../../server';
import L from '../../locales/LocalizedString';
import { Search } from '@material-ui/icons';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme)=>({
    viewPoint:{
        display: 'flex',
        flexDirection:'column',
        top:0,left:0,bottom:0,right:0,position:'absolute'
    },
    selectionBox :{
        marginBottom: 10,
        display: 'flex',
        flexDirection:'column',
    },
    fieldContainer :{
        display: 'flex',
        flexDirection:'row',
    },    
    fieldLabel:{
        width:50,
    },
    field:{
        flexGrow:1,
        alignContent:'left'
    },
    datePicker:{
        width:120
    },
    table: {    
    },
    selectedRow: {
        backgroundColor: indigo[100],
    },
    evenRow: {
      backgroundColor: indigo[50],
    },
    oddRow: {
  
    },
    tableHolder:{
       flexGrow:1
    }
  }));

const retrieveLocationTraces = async (serialNumber,fromDate,toDate) =>{    
    const sessionRef = getSessionRef();
    let result = null;
    try{
        const response = await  fetch(`${SERVER_URL}/monitor/devices/${serialNumber}/locationTraces?from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}&token=${encodeURIComponent(sessionRef)}`);
        const json = await response.json();
        result = json;
    }catch(e){
        if(e === 'NetworkError when attempting to fetch resource.'){
            throw new Error(L('network.unableToAccessServer'));
        }else {
            throw new Error(L('network.unableToAccessServer'));
        }            
    }
    return result;
};


export default ({selectedTracker,setSelectedTracker})=>{
    const classes = useStyles();
    const [events,setEvents] = useState([]);    
    const fromRef = useRef();
    const toRef = useRef();
    
    const getRowClass=(row,idx)=>{
        return (idx%2 == 0)?classes.evenRow:classes.oddRow;
    }
    const onRowClick = (row)=>{

    }    
    const onSearch = async () =>{
        const fromDate = fromRef.current.state.value.format('YYYY-MM-DD HH:mm:ss');
        const toDate = toRef.current.state.value.format('YYYY-MM-DD HH:mm:ss');

        try{            
            const traces = await retrieveLocationTraces(selectedTracker.serialNumber,fromDate,toDate);
            const points = [];
            traces.map(e=>{
                points.push([e.latitude,e.longitude]);
            });
            selectedTracker.pathPoints=points;
            selectedTracker.viewTrace=true;
            selectedTracker.shouldTrack=false;
            setSelectedTracker(selectedTracker);
            
        }catch(e){
            toast.error(e.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

    }
    
    return(
        <div className={classes.viewPoint}>
        <Paper className={classes.selectionBox}>
            <div style={{margin:5}}>
                <div className={classes.fieldContainer}>                
                    <Box className={classes.fieldLabel}>{L('Device')}</Box><Box className={classes.fieldLabel}>{selectedTracker.serialNumber}</Box>
                </div>            
                <Box className={classes.fieldContainer}>
                    <Box className={classes.fieldContainer} style={{width:'50%'}}>                                
                        <Box style={{width:20}}>{L('general.from')}</Box>
                        <Box >
                                <DatePicker className={classes.datePicker} label="" ref={fromRef}/>
                        </Box>
                    </Box>
                    <Box className={classes.fieldContainer} style={{width:'50%'}}>                                
                        <Box style={{width:20}}>{L('general.to')}</Box>
                        <Box >
                            <DatePicker className={classes.datePicker} label="" ref={toRef}/>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.fieldContainer} style={{alignContent:'center'}}>
                    <IconButton onClick={onSearch}><Search/></IconButton>
                </Box>
            </div>
        </Paper>  


        <TableContainer component={Paper} className={classes.tableHolder}>
            <Table stickyHeader  className={classes.table} size="small">          
            <TableHead>
            <TableRow>
                    <TableCell>
                        Time
                    </TableCell>
                    <TableCell component="th" scope="row">                        
                        Information
                    </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {(!events.length)?
                    <TableRow><TableCell colSpan="2" align="center">{L('dataAware.emptyMessage')}</TableCell></TableRow>
                :events.map((row,idx) => (
                <TableRow key={row.serialNumber} 
                          className={getRowClass(row,idx)} 
                          onClick={()=>onRowClick(row)}>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    </div>  
    
    );
};