import L from 'leaflet';
export const ConnectedIcon = new L.Icon({    
    iconUrl: 'js/leaflet/images/marker-icon-2x-green.png',
    shadowUrl: 'js/leaflet/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

export const DisconnectedIcon = new L.Icon({
    iconUrl: 'js/leaflet/images/marker-icon-2x-grey.png',
    shadowUrl: 'js/leaflet/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

export const SelectedIcon = new L.Icon({
    iconUrl: 'js/leaflet/images/marker-icon-2x.png',
    shadowUrl: 'js/leaflet/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});