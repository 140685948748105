import React, { useState, useCallback } from 'react';
//import VirtualizedTable from '../../components/virtualizedTable';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {green, grey, indigo} from '@material-ui/core/colors';
import {Wifi, MoreVert, Visibility, VisibilityOff} from '@material-ui/icons';
import {TraceIcon} from '../../icons';
import L from '../../locales/LocalizedString';


const useStyles = makeStyles((theme)=>({
  viewPoint:{
      display: 'flex',
      flexDirection:'column',
      top:0,left:0,bottom:0,right:0,position:'absolute'
  },
  table: {    
  },
  selectedRow: {
      backgroundColor: indigo[100],
  },
  evenRow: {
    backgroundColor: indigo[50],
  },
  oddRow: {

  },
  tableHolder:{
     flexGrow:1
  }
}));
let visibilityState = true;

export default ({ trackers,updateTrackerStatus, deviceSelectionCallback,selectedTracker}) =>{
    const classes = useStyles();


    console.log('SelectedTracker' + JSON.stringify(selectedTracker));
    const updateVisibilityStatus=(serialNumber,status)=>{
        updateTrackerStatus(serialNumber,{visible:status});  
    }; 
    const updateTrackingStatus=(serialNumber,status)=>{
        updateTrackerStatus(serialNumber,{shouldTrack:status,viewTrace:status});  
    }; 
    const onRowClick=(row)=>{
        if(selectedTracker && row.serialNumber == selectedTracker.serialNumber){        
            deviceSelectionCallback(null);
        }else{
            deviceSelectionCallback(row);
        }
    }
    const getRowClass=(row,idx)=>{
        return (selectedTracker != null && selectedTracker.serialNumber == row.serialNumber)?classes.selectedRow:(idx%2 == 0)?classes.evenRow:classes.oddRow;
    }
    const toggleVisibility=()=>{
        visibilityState = !visibilityState;
        updateTrackerStatus(null,{visible:visibilityState});  
    };
    return (
      <div className={classes.viewPoint}>
          
            <TableContainer component={Paper} className={classes.tableHolder}>
                <Table stickyHeader  className={classes.table} size="small">          
                <TableHead>
                <TableRow>
                        <TableCell padding="checkbox" onClick={toggleVisibility}>
                            {visibilityState?<Visibility fontSize="small"/>:<VisibilityOff fontSize="small"/>}
                        </TableCell>
                        <TableCell padding="checkbox">
                            <TraceIcon fontSize="small"/>       
                        </TableCell>
                        <TableCell component="th" scope="row">                        
                        
                        </TableCell>
                        <TableCell padding="checkbox">
                            
                        </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {(!trackers.length)?
                        <TableRow><TableCell colSpan="4" align="center">{L('dataAware.emptyMessage')}</TableCell></TableRow>
                    :trackers.map((row,idx) => (
                    <TableRow key={row.serialNumber} 
                              className={getRowClass(row,idx)} 
                              onClick={()=>onRowClick(row)}>
                        <TableCell padding="checkbox">
                        <Checkbox checked={row.visible} 
                                   color="default"
                                   size="small"
                                   onChange={(event)=>{                                      
                                      updateVisibilityStatus(row.serialNumber,event.target.checked);
                                   }}
                                   onClick={(event)=>{
                                    
                                    event.stopPropagation();
                                   }}/>

                        </TableCell>
                        <TableCell padding="checkbox">
                        <Checkbox checked={row.shouldTrack} 
                                   color="default"
                                   size="small"
                                   onClick={(event)=>{
                                    
                                    event.stopPropagation();
                                   }}
                                  onChange={(event)=>{
                                     
                                      updateTrackingStatus(row.serialNumber,event.target.checked)
                                  }}/>

                        </TableCell>

                        <TableCell component="th" scope="row">                        
                        {row.serialNumber}
                        </TableCell>
                        <TableCell align="center" style={{padding:0,width:20}}>
                            <Wifi style={{color:(row.connected)?green[500]:grey[600]}} fontSize="small"/>
                        </TableCell>

                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
      </div>  
    );
};