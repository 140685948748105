import React from 'react';
import { IntlProvider } from 'react-intl';
import {IntlReact} from './locales/LocalizedString'
import locales from './locales';

import SinginPage from './pages/signin';
import MainPage from './pages/main/index';
import {setSessionRef} from './server';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function App(){

  const [profile,setProfile] = React.useState({
    "sessionKey": "EWduEEUy7xyxIt93.G8xDk1HvSM0vZd4rCRC4ncBJjE-",
    "fullname": "مدیر سامانه",
    "avatar": null,
    "accessKeys": [
        "*"
    ]
});
  const loginHandler=(profile)=>{
    setProfile(profile);
    setSessionRef(profile.sessionKey);
  };
  
  const signoutHandler=()=>{
    setProfile(null);
    setSessionRef(null);
  };

  if(profile){
    setSessionRef(profile.sessionKey);
  }
  return (
    <IntlProvider locale="fa" messages={locales.fa}>
      <React.Fragment>   
        <IntlReact/>           
        {profile != null?<MainPage signoutHandler={signoutHandler}/>:<SinginPage successCallback={loginHandler}/>}      
        <ToastContainer />
      </React.Fragment>    
    </IntlProvider>
  );

}
