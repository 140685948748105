import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';

import {SERVER_URL,getSessionRef} from '../../server';
import { toast } from 'react-toastify';
import L from '../../locales/LocalizedString';


import Sidebar from './sidebar';
import Mapview from './map';

const UPDATE_INTERVAL = 30000;
const originalList = {
    objectCount:0,
    objectMap:{}
};

const retrieveTrackerForMonitoring = async () =>{    
    const sessionRef = getSessionRef();
    let result = null;
    try{
        const response = await  fetch(`${SERVER_URL}/monitor/devices?token=${encodeURIComponent(sessionRef)}`);
        const json = await response.json();
        result = json;
    }catch(e){
        if(e === 'NetworkError when attempting to fetch resource.'){
            throw new Error(L('network.unableToAccessServer'));
        }else {
            throw new Error(L('network.unableToAccessServer'));
        }            
    }
    return result;
};

const updateItem = (originalItem,newItem)=>{
    originalItem = (!originalItem)?{}:originalItem;
    return Object.assign(originalItem,newItem);
}

const retrieveItems= async () => {
    const newList = await retrieveTrackerForMonitoring();
    if(newList.length > 0){
        if(originalList.objectCount > 0){
            newList.map(item=>{
                if(originalList.objectMap[item.serialNumber]){                    
                    const object = originalList.objectMap[item.serialNumber];
                    if(object.shouldTrack){
                        let shouldPush = false;
                        if(object.pathPoints.length === 0){
                            shouldPush = true;
                        }else{
                            const lastPoint = object.pathPoints[object.pathPoints.length - 1];
                            if(lastPoint[0] !== object.properties.lastKnownLattitude || lastPoint[1] !== object.properties.lastKnownLongitude){
                                shouldPush = true;
                            }
                        }
                        if(shouldPush){
                            object.pathPoints.push([object.properties.lastKnownLattitude,object.properties.lastKnownLongitude]);
                        }
                    }
                    originalList.objectMap[item.serialNumber] = updateItem(object,item);
                }else{
                    originalList.objectMap[item.serialNumber] = updateItem({pathPoints:[],viewTrace:false,shouldTrack:false,visible:true},item);
                }
            });
            Object.keys(originalList.objectMap)
                  .filter(item=>newList.find(newListItem=>newListItem.serialNumber === item.serialNumber) != -1)
                  .map(item=>delete originalList.objectMap[item.serialNumber]);
        }else{            
            newList.map(item=>originalList.objectMap[item.serialNumber] = updateItem({pathPoints:[],viewTrace:false,shouldTrack:false,visible:true},item));
            originalList.objectCount = newList.length;
        }    
    }else{
        if(originalList.objectCount > 0){
            //Clean up
            originalList.objectCount = 0;
            originalList.objectMap = {};
        }
    }
}

const applyFilter = () =>{
    const objectMapValues = Object.values(originalList.objectMap);
            
    const trackers = objectMapValues
                            .sort((a,b)=>{
                                if (a > b) {
                                    return -1;
                                }
                                if (b > a) {
                                    return 1;
                                }
                                return 0;
                            });
    return trackers;
}
//TODO:What if server throws exception?
export default function MapView(){
    const [trackers,setTrackers] = useState([]);
    const [selectedTracker,setSelectedTracker] = useState(null);
    
    const updaters = {
        setTrackers
    };
    const doUpdate = async ()=>{
        try{
            await retrieveItems(originalList);
            setTrackers(applyFilter());
        }catch(e){
            toast.error(e.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    
    useEffect(() => {            
        
        if(trackers.length == 0){
            doUpdate();
        }else{
            const timeout = setTimeout(()=>doUpdate(),UPDATE_INTERVAL);    
            return () => {      
                clearTimeout(timeout);
            };  
        }        
    });

    const updateTrackerStatus = (serialNumber,obj) =>{
        if(serialNumber){
            const object = 
            Object.assign(originalList.objectMap[serialNumber],obj);
            if(!originalList.objectMap[serialNumber].shouldTrack){
                originalList.objectMap[serialNumber].pathPoints = [];
            }
        }else{
            Object.values(originalList.objectMap).map(item=>{
                if(selectedTracker != null){
                    if(selectedTracker.serialNumber !== item.serialNumber){
                        Object.assign(item,obj);
                    }
                }else{
                    Object.assign(item,obj);
                }
                
            });
        }
        
        
        setTrackers(applyFilter());
    }
    const deviceSelectionCallback = (device)=>{
        if(device){
            updateTrackerStatus(device.serialNumber,{visible:true});
        }
        
        setSelectedTracker(device);
    };
    return(
        <Box display="flex" flexDirection="row" height="100%">
                <Sidebar trackers={trackers} 
                        updateTrackerStatus={updateTrackerStatus}
                        deviceSelectionCallback={deviceSelectionCallback}
                        selectedTracker={selectedTracker}
                        setSelectedTracker={setSelectedTracker}/>
             <Divider orientation="vertical" flexItem />
            <Box flexGrow="1">
                <Box display="flex" flexDirection="column" height="100%">
                    <Box flexGrow="1"> <Mapview trackers={trackers} selectedTracker={selectedTracker}/></Box>
                </Box>
            </Box>
        </Box>
    );
}