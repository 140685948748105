import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress } from '@material-ui/core';

import L from '../../locales/LocalizedString';
import {SERVER_URL} from '../../server';
import TextField from '@material-ui/core/TextField';

import { useFormik  } from 'formik';
import * as Yup from 'yup';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{direction:'ltr'}}>
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        http://pantrack.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  viewPort: {
    display:'flex',
    height:'100%',
  },

  dialog: {
        backgroundColor: 'white',
    },
  paper: {
    margin: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default ({successCallback}) => {
  const classes = useStyles();


  const  onLoginHandler =async (values, actions) =>{
    
    try{
        const response = await  fetch(`${SERVER_URL}/security/sessions?username=${encodeURIComponent(values.username)}&password=${encodeURIComponent(values.password)}`,{
            method:'POST'
        });
        const json = await response.json();
        if(json.errorCode === 'USER_NOT_FOUND'){
          actions.setFieldError('general', L('service.security.login.userNotFound'));
        }else{
          successCallback(json);
        }        
    }catch(e){
        if(e === 'NetworkError when attempting to fetch resource.'){
          actions.setFieldError('general', L('network.unableToAccessServer'));            
        }else {
          actions.setFieldError('general', L('network.serverInternalError'));            
        }        
    }finally{
      actions.setSubmitting(false);
    }
    
};

  const formik = useFormik({
    initialValues:{
      username:'',
      password:'',
    },
    validationSchema:Yup.object().shape({
      username: Yup.string()                
        .required(L('validation.required')),
        password: Yup.string()
        .required(L('validation.required')),
    }),
    onSubmit:onLoginHandler
  });
  return (
    <div className={classes.viewPort}>
    <Container component="main" maxWidth="xs">      
     <Box display="flex" flexDirection="column"  height="100%">
      <Box flexGrow="1">
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
      <Box border={1} borderRadius={5} className={classes.dialog} >
        <div className={classes.paper} >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />            
          </Avatar>
          <Typography variant="h4">{L('loginPage.title')}</Typography>
          <Typography style={{ color: 'red' }}>{formik.errors.general}</Typography>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField variant="outlined"
                      margin="normal"                                              
                      fullWidth                                                                     
                      onChange={formik.handleChange} onBlur={formik.handleBlur}
                      label={L('loginPage.lbUsername')}                       
                      name="username"                                              
                      helperText={(formik.errors.username && formik.touched.username) && formik.errors.username}
                      value={formik.values.username}
                      autoFocus
                      />
            <TextField variant="outlined"
                      margin="normal"                               
                      fullWidth              
                      onChange={formik.handleChange} onBlur={formik.handleBlur}
                      label={L('loginPage.lbPassword')}
                      name="password"
                      type="password"                       
                      helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                      value={formik.values.password}
                      />
            <Button type="submit" fullWidth variant="contained"  color="primary"  className={classes.submit}>
              {L('loginPage.btnLogin')}
            </Button>
          </form>
          <Backdrop className={classes.backdrop} open={formik.isSubmitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        </Box>
        </Box>
        </Box>
        <Box>
          <Copyright />
        </Box>
      </Box>    
    </Container>
    </div>
  );
}