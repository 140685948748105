import React from 'react';
import { SvgIcon } from '@material-ui/core';


export function TraceIcon(props) {
    return (
      <SvgIcon viewBox="0 0 515.458 515.458" {...props}>
			<path d="M298.794,386.711c27.805,9.522,52.357,15.587,87.633,26.427C372.875,584.374,210.952,516.371,298.794,386.711z    
					 M443.366,229.409c-1.826-51.415-10.882-118.86-83.017-108.292c-33.815,8.825-58.8,45.962-70.551,110.035   
					 c-6.454,35.229-2.701,84.678,4.912,114.32c6.951,20.889,4.587,19.605,12.058,23.572c28.916,6.514,57.542,13.725,86.693,21.078   
					 C423.075,369.209,447.397,258.182,443.366,229.409z M220.752,225.463c7.607-29.646,11.36-79.095,4.909-114.32   
					 C213.919,47.067,188.931,9.924,155.11,1.105C82.975-9.463,73.919,57.981,72.093,109.399   
					 c-4.031,28.768,20.294,139.802,49.911,160.711c29.149-7.353,57.771-14.558,86.696-21.078   
					 C216.162,245.069,213.798,246.352,220.752,225.463z M129.029,293.132c13.547,171.234,175.47,103.231,87.63-26.427   
					 C188.854,276.228,164.304,282.292,129.029,293.132z" fill="#6f6f6f"/>


      </SvgIcon>
    );
  }



export function DTSIcon(props) {
    return (
      <SvgIcon viewBox="0 0 512 512" {...props}>
        <path d="M155.69,229.281h-14.815v51.791h15.811c13.57,0,23.281-10.458,23.281-25.771
            C179.967,239.863,169.758,229.281,155.69,229.281z"/>
        <path  d="M471.49,186.945h-40.726v44.44H400.88l-60.86-99.99h-91.95V96.21h66.036V66.59H141.9v29.62h66.66v35.185
            h-81.475l-43.21,46.295H56.73v53.695H34.515v-64.8H6.74v172.19h27.775v-59.246H56.73v37.016h27.755l81.49,90.75h239.77V357.3
            h25.024v49.995h40.726c0,0,22.225-5.535,22.225-110.165C493.715,297.135,495.555,186.945,471.49,186.945z M155.317,298.75h-36.602
            v-87.147h37.473c27.39,0,46.313,17.678,46.313,43.573C202.501,280.947,183.329,298.75,155.317,298.75z M251.68,298.75h-22.16
            v-69.345H203.5v-17.927h74.449v17.927h-26.27V298.75z M323.018,299.746c-26.269,0-45.814-19.297-45.814-44.818
            c0-25.149,20.044-44.072,46.563-44.072c12.947,0,26.393,5.727,35.107,14.69l-12.823,15.189c-5.727-6.972-14.192-11.33-21.911-11.33
            c-13.819,0-24.277,10.956-24.277,25.272c0,14.566,10.458,25.646,24.277,25.646c7.345,0,15.811-3.983,21.911-10.209l12.947,13.695
            C349.66,293.396,335.841,299.746,323.018,299.746z"/>
      </SvgIcon>
    );
  }

export function SettingsIcon(props) {
    return (
      <SvgIcon viewBox="0 0 512 512" {...props}>
        <path d="M233.333,66.667v-8.333c0-13.75-11.25-25-25-25H125c-13.75,0-25,11.25-25,25v8.333H0v66.667h100v8.333   
                c0,13.75,11.25,25,25,25h83.333c13.75,0,25-11.25,25-25v-8.333h300V66.667H233.333z M133.333,133.333V66.667H200v66.667H133.333z    
                M433.333,225c0-13.75-11.25-25-25-25H325c-13.75,0-25,11.25-25,25v8.333H0V300h300v8.333c0,13.751,11.25,25,25,25h83.334   
                c13.75,0,25-11.249,25-25V300h100v-66.667h-100V225z M333.333,300v-66.667H400V300H333.333z M233.333,391.667   
                c0-13.751-11.25-25-25-25H125c-13.75,0-25,11.249-25,25V400H0v66.667h100V475c0,13.75,11.25,25,25,25h83.333   
                c13.75,0,25-11.25,25-25v-8.333h300V400h-300V391.667z M133.333,466.667V400H200v66.667H133.333z"/>

      </SvgIcon>
    );
  }

export function DashboardIcon(props) {
    return (
      <SvgIcon viewBox="0 0 512 512"  {...props}>        
        <path  d="M323.62,211.5l-86.625,114.126c-28.061,7.996-48.616,33.807-48.616,64.431
			c0,37.008,29.999,67.004,67.002,67.004c37.008,0,67.005-29.996,67.005-67.004c0-13.405-3.952-25.881-10.731-36.355L323.62,211.5z
			 M255.384,411.394c-11.786,0-21.339-9.553-21.339-21.337c0-11.785,9.553-21.336,21.339-21.336
			c11.783,0,21.337,9.551,21.337,21.336C276.721,401.841,267.167,411.394,255.384,411.394z"/>
		<path  strokeWidth="10" strokeMiterlimit="10" d="M507,305.937
			c0-138.624-112.378-250.998-251-250.998c-138.627,0-251,112.374-251,250.998c0,0.011,0,0.021,0,0.032c0,0.01,0,0.02,0,0.028
			c0,9.533,0,18.996,0,28.308c0,14.607,0,28.827,0,42.286c0,0.029,0,0.055,0,0.081l42.59-0.02c0-0.021,0-0.04,0-0.062h53.099
			v-42.286H47.307c-0.081-9.208-0.146-18.568-0.146-28.308c0-6.987,0.35-13.895,1.021-20.707l0.008-0.009
			c0.138-1.406,0.292-2.811,0.457-4.208c0.003-0.027,0.006-0.056,0.01-0.084c0.152-1.29,0.32-2.571,0.498-3.853
			c0.034-0.248,0.067-0.494,0.104-0.738c0.173-1.228,0.355-2.453,0.55-3.674c0.035-0.216,0.072-0.429,0.108-0.646
			c0.613-3.761,1.329-7.486,2.141-11.18c0.074-0.34,0.146-0.68,0.223-1.018c0.224-1,0.461-1.996,0.702-2.99
			c0.126-0.528,0.254-1.057,0.386-1.586c0.232-0.933,0.472-1.863,0.716-2.792c0.154-0.581,0.313-1.161,0.472-1.742
			c0.246-0.895,0.49-1.794,0.747-2.687c0.175-0.608,0.359-1.21,0.54-1.819c0.44-1.472,0.895-2.94,1.365-4.398
			c0.238-0.74,0.473-1.481,0.719-2.217c0.247-0.736,0.504-1.47,0.76-2.204c0.26-0.75,0.521-1.501,0.79-2.247
			c0.257-0.707,0.516-1.414,0.777-2.12c0.294-0.787,0.594-1.572,0.896-2.355c0.253-0.656,0.506-1.312,0.766-1.964
			c0.378-0.945,0.765-1.888,1.154-2.825c0.2-0.479,0.394-0.963,0.596-1.441c0.588-1.384,1.189-2.761,1.805-4.128
			c0.21-0.462,0.425-0.922,0.638-1.382c0.442-0.967,0.891-1.932,1.348-2.892c0.246-0.511,0.494-1.021,0.744-1.531
			c0.455-0.934,0.916-1.861,1.383-2.786c0.238-0.471,0.477-0.942,0.718-1.41c0.556-1.075,1.122-2.145,1.694-3.208
			c0.159-0.297,0.314-0.595,0.476-0.89c0.762-1.401,1.54-2.795,2.333-4.177c0.052-0.089,0.105-0.176,0.155-0.265
			c0.734-1.274,1.483-2.539,2.245-3.795c0.185-0.304,0.371-0.606,0.557-0.91c0.656-1.07,1.318-2.135,1.993-3.193
			c0.192-0.302,0.386-0.603,0.579-0.903c0.745-1.159,1.502-2.31,2.271-3.452c0.104-0.153,0.203-0.309,0.309-0.461
			c1.841-2.723,3.743-5.397,5.707-8.025c0.033-0.045,0.065-0.088,0.1-0.131c0.94-1.256,1.895-2.499,2.862-3.733
			c0.085-0.109,0.17-0.217,0.255-0.324c2.926-3.716,5.978-7.324,9.145-10.83l37.46,37.463l25.914-25.913l-36.511-36.513
			c30.855-24.016,68.63-39.553,109.831-43.136v54.105h36.648V97.968c41.053,3.569,78.704,19.011,109.5,42.882l-36.766,36.767
			l25.912,25.913l37.775-37.772c6.677,7.362,12.838,15.203,18.422,23.467c0.007,0.01,0.013,0.021,0.02,0.03
			c0.891,1.321,1.768,2.653,2.628,3.994c0.077,0.121,0.157,0.242,0.231,0.362c0.85,1.324,1.685,2.66,2.501,4.006
			c0.025,0.042,0.053,0.085,0.079,0.13c1.718,2.824,3.368,5.693,4.949,8.604c0.071,0.13,0.14,0.262,0.213,0.394
			c0.674,1.249,1.34,2.509,1.992,3.773c0.155,0.305,0.311,0.609,0.464,0.912c0.578,1.136,1.145,2.279,1.703,3.426
			c0.154,0.319,0.31,0.636,0.461,0.954c0.626,1.3,1.234,2.604,1.83,3.916c0.062,0.135,0.123,0.268,0.183,0.401
			c0.666,1.467,1.311,2.944,1.938,4.43c0.115,0.271,0.226,0.544,0.339,0.815c0.486,1.16,0.963,2.325,1.429,3.497
			c0.196,0.496,0.389,0.995,0.585,1.493c0.374,0.964,0.74,1.933,1.101,2.904c0.208,0.554,0.412,1.107,0.613,1.663
			c0.349,0.958,0.684,1.92,1.015,2.884c0.188,0.541,0.381,1.081,0.563,1.624c0.432,1.292,0.854,2.588,1.26,3.885
			c0.205,0.647,0.398,1.294,0.596,1.94c0.291,0.968,0.585,1.938,0.862,2.912c0.213,0.73,0.411,1.464,0.613,2.197
			c0.209,0.759,0.419,1.519,0.618,2.28c0.208,0.797,0.414,1.599,0.617,2.398c0.173,0.691,0.338,1.379,0.504,2.069
			c0.204,0.857,0.41,1.712,0.603,2.572c0.134,0.576,0.254,1.155,0.379,1.734c0.732,3.361,1.382,6.754,1.947,10.175
			c0.069,0.418,0.144,0.838,0.209,1.259c0.176,1.09,0.339,2.189,0.497,3.289c0.056,0.395,0.111,0.793,0.165,1.191
			c0.157,1.157,0.311,2.315,0.452,3.48c0.025,0.217,0.047,0.432,0.071,0.644c0.149,1.269,0.291,2.541,0.415,3.816l0.006,0.006
			c0.672,6.813,1.021,13.722,1.021,20.71c0,9.624,0.035,19.067,0.03,28.327H411.31v42.288H507v-0.021c0-14.354,0-28.176,0-42.267
			c0-9.249,0-18.612,0-28.327c0-0.009,0-0.019,0-0.028C507,305.958,507,305.947,507,305.937z"/>  

</SvgIcon>
);
}
