import React from 'react';
import { Box, Divider } from '@material-ui/core';
import MainToolbar from './toolbar';
import MapView from '../map';

class MainPage extends React.Component{
    
    
    render(){
        return(
            <Box display="flex" flexDirection="column" height="100%" width="100%">                
                <MainToolbar callback={this.toolbarCallback}/>                
                <Box flexGrow="1">
                    <MapView/>
                </Box>
    
            </Box>
        );
    }

    toolbarCallback = (item) =>{
        if(item == 'ExitApp'){
            this.props.signoutHandler();
        }
    
    }
}



export default MainPage;