import React from 'react';


export const SERVER_URL = 'http://backoffice.metavas.com/pantrack';
//export const SERVER_URL = 'http://192.168.1.40:8080';
let sessionRef = null;


export const setSessionRef = (id) =>{
    sessionRef = id;
}

export const getSessionRef = () =>{
    return sessionRef;
}