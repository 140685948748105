export default {    
    'btnSave':'ذخیره',
    'btnCancel':'انصراف',
    'dataAware.lbLoading':'در حال بارگذاری...',
    'dataAware.emptyMessage': 'داده ای یافت نشد',
    'network.unableToAccessServer':'امکان دسترسی به خدمت دهنده وجود ندارد!',
    'service.security.login.userNotFound':'امکان ورود با این نام کاربری/کلمه عبور وجود ندارد!',
    'validation.required': 'ورود داده برای این فیلد اجباری است!',
    'validation.notAValidEmail':'لطفا یک آدرس پست الکترونیکی معتبر وارد نمایید',
    'serverInternalError':'خطا در اجرای دستور...',
    'loginPage.title': 'ورود',
    'loginPage.lbUsername': 'نام کاربری',
    'loginPage.lbPassword': 'کلمه عبور',
    'loginPage.btnLogin': 'ورود',

    'main.tooltip.map':'نقشه',
    'main.tooltip.dashboard':'داشبورد مدیریتی',
    'main.tooltip.deviceManagement':'مدیریت ردیابها',
    'main.tooltip.settings':'تنظیمات',
    'main.tooltip.dts':'گزارش خطاها',
    'main.tooltip.exit':'خروج',

    'map.sidebar.detail.DeviceDetail':'ردیاب',
    'map.sidebar.header.tooltip.devices':'ردیابها',
    'map.sidebar.header.tooltip.events':'وقایع',
    'map.sidebar.header.tooltip.history':'تاریخچه',
    'Device':'ردیاب',
    'Device.deviceInfo':'مشخصات',
    'Device.tags':'برچسب ها',
    'Device.properties':'سایر',
    'Device.model':'مدل',
    'Device.serialNumber':'شماره سریال',
    'Device.properties.alias':'نام',    
    'Device.properties.assetId':'کد دارایی',    
    'Device.properties.assetDescription':'شرح',    
    'Device.properties.meteringType':'شیوه اندازه گیری کارکرد',
    'Device.properties.meteringType.ByDistance':'کیلومتر',
    'Device.properties.meteringType.ByRuntime':'ساعت',
    'Device.properties.meteringType.ByDistanceAndRuntime':'کیلومتر/ساعت',
    'Device.properties.lastKnownLattitude':' آخرین عرض جغرافیایی',    
    'Device.properties.lastKnownLongitude':'آخرین طول جغرافیایی',    
    'EditDevicePage.newDeviceTitle':'ایجاد ردیاب',
    'EditDevicePage.editDeviceTitle':'ویرایش ردیاب',

    'general.from':'از',
    'general.to':'تا',
}