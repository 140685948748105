import React from 'react'
import { injectIntl } from 'react-intl'

// Does not actually render anything visible.
// We need it to provide access to internationalization for classes
// which are not a React component
class LocalizeComponent extends React.Component {
  static __singletonRef = React.createRef
  static getInstance () {
    return LocalizeComponent.__singletonRef
  }

   constructor () {
    super()
    LocalizeComponent.__singletonRef = this
  }

   formatMessage (id) {
    const { intl } = this.props
    return intl.formatMessage({id})
  }
  render () {
    return false
  }
}

 export const IntlReact = injectIntl(LocalizeComponent)

 export default (id) => {
  return LocalizeComponent.getInstance().formatMessage(id)
}