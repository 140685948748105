import React from 'react';
import { Toolbar, IconButton, Box, Tooltip } from '@material-ui/core';
import { ExitToApp, Map as MapIcon, DevicesOther} from '@material-ui/icons';
import {  DashboardIcon, SettingsIcon, DTSIcon} from '../../icons';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import L from '../../locales/LocalizedString';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

export default function MainToolbar(props){
    const callback = (item) =>{
        if(props.callback){
            props.callback(item);
        }
    }
    
    return(
        <Toolbar variant="dense" style={{backgroundColor:deepPurple[700]}}>
            <LightTooltip title={L('main.tooltip.map')}><IconButton onClick={()=>callback('ShowMap')}><MapIcon style={{color:'white'}}/></IconButton></LightTooltip>
            <LightTooltip title={L('main.tooltip.dashboard')}><IconButton onClick={()=>callback('ShowDashboard')}><DashboardIcon style={{color:'white'}}/></IconButton></LightTooltip>
            <LightTooltip title={L('main.tooltip.deviceManagement')}><IconButton onClick={()=>callback('ShowDeviceManagement')}><DevicesOther style={{color:'white'}}/></IconButton></LightTooltip>                    
            <LightTooltip title={L('main.tooltip.settings')}><IconButton onClick={()=>callback('ShowSettings')}><SettingsIcon style={{color:'white'}}/></IconButton></LightTooltip>                    
            <LightTooltip title={L('main.tooltip.dts')}><IconButton onClick={()=>callback('ShowDTS')}><DTSIcon style={{color:'white'}}/></IconButton></LightTooltip>                
            <Box flexGrow="1"/>
            <LightTooltip title={L('main.tooltip.exit')}><IconButton onClick={()=>callback('ExitApp')}><ExitToApp style={{color:'white',transform: "scaleX(-1)"}} /></IconButton></LightTooltip>
        </Toolbar>        
    );
}
