import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { faIR } from '@material-ui/core/locale';



import VazirWoff2 from './fonts/Vazir.woff2';
import VazirWoff from './fonts/Vazir.woff';
import VazirTtf from './fonts/Vazir.ttf';
import { CssBaseline } from '@material-ui/core';


const vazir = {
  fontFamily: 'Vazir',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `    
    local('Vazir'),    
    url(${VazirWoff2}) format('woff2'),
    url(${VazirWoff}) format('woff'),
    url(${VazirTtf}) format('ttf')
  `,
};


// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({    
    typography: {
      fontFamily: 'Vazir, Roboto',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [vazir],
        },
      },
    },
    direction: 'rtl',    
  }, faIR);
  
ReactDOM.render(
  
<StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>       
      <React.Fragment>
      
            <CssBaseline/>     
            <App />   
        
      </React.Fragment>     
    </ThemeProvider>
</StylesProvider>

, document.getElementById('root'));
