import React, { useState, useRef } from 'react';
import { Tooltip, Box, Divider, Tabs, Tab, Typography, Paper, Fab, Backdrop  } from '@material-ui/core';
import {MyLocation,GolfCourse, Timeline} from '@material-ui/icons';

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import L from '../../locales/LocalizedString';


import { makeStyles } from '@material-ui/core/styles';

import DeviceHistory from './deviceHistory';
import DeviceList from './deviceList';
const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
    root: {     
        minWidth: 72,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: '50px'
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fffff',
        
      },  
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <div style={{top:10,left:10,bottom:10,right:10,position:'absolute'}}>{children}</div>}
      </Typography>
    );
  }


export default ({trackers,updateTrackerStatus,deviceSelectionCallback,selectedTracker,setSelectedTracker})=>{
    const boxRef = useRef();
    const closeRef = useRef();
    const openRef = useRef();
    const backdropRef = useRef();
    const [currentTab,setCurrentTab] = useState(0);
    const handleTabChange = (e,index)=>{
        setCurrentTab(index);
    };
    const a11yProps = (index)=>{
        return {
            id: `scrollable-width-tab-${index}`,
            'aria-controls': `scrollable-width-tabpanel-${index}`,
            
        }
    }
    const classes = useStyles();

    let open = true;
    return(
        <div style={{height:'100%',position:'relative'}}>            
        <Box width="360px" display="flex" flexDirection="column" height="100%" ref={boxRef}>
            <Tabs value={currentTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable">
                <Tab classes={{root:classes.root}} icon={<Tooltip title={L('map.sidebar.header.tooltip.devices')}><MyLocation/></Tooltip>} color="secondary" {...a11yProps(0)} />
                <Tab classes={{root:classes.root}} icon={<Tooltip title={L('map.sidebar.header.tooltip.events')}><GolfCourse/></Tooltip>} {...a11yProps(1)} />
                {(selectedTracker != null)?
                    <Tab classes={{root:classes.root}} icon={<Tooltip title={L('map.sidebar.header.tooltip.history')}><Timeline/></Tooltip>} {...a11yProps(2)} />:null
                }
                
            </Tabs>
            
            <div style={{backgroundColor:'white',flexGrow:1,position:'relative'}}>
                <TabPanel value={currentTab} index={0} style={{top:0,left:0,bottom:0,right:0,position:'absolute'}} >
                    <DeviceList trackers={trackers} 
                                updateTrackerStatus={updateTrackerStatus}
                                deviceSelectionCallback={deviceSelectionCallback}
                                selectedTracker={selectedTracker}/>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <DeviceHistory selectedTracker={selectedTracker} setSelectedTracker={setSelectedTracker}/>
                </TabPanel>

            </div>
        </Box>
        <div style={{position:'absolute',top:5,left:5}}>
        <Fab size="small" color="primary" style={{zIndex:11000}} onClick={()=>{
            open = !open;
            if(open){
                boxRef.current.classList.remove(classes.drawerClose);
                boxRef.current.classList.add(classes.drawerOpen);
                openRef.current.style.display = 'block';
                closeRef.current.style.display = 'none';
                backdropRef.current.style.display = 'none';
            }else{
                boxRef.current.classList.remove(classes.drawerOpen);
                boxRef.current.classList.add(classes.drawerClose);
                openRef.current.style.display = 'none';
                closeRef.current.style.display = 'block';
                backdropRef.current.style.display = 'block';
            }
        }}>
                <ChevronLeftIcon style={{display:open?'block':'none'}} ref={openRef}/>
                <ChevronRightIcon style={{display:open?'none':'block'}} ref={closeRef}/>                
            </Fab>
        </div>
        <div style={{zIndex:10999,backgroundColor: 'white',position:'absolute',top:0,left:0,right:0,bottom:0,display:open?'none':'block'}} ref={backdropRef}>
        
         </div>   
          
        </div> 
    );

};