import React, { useState, useEffect } from 'react';
import { Map, Marker, Popup, TileLayer,FeatureGroup, Polyline } from 'react-leaflet'
import {latLngBounds} from 'leaflet';
import { makeStyles } from '@material-ui/core/styles';
import {ConnectedIcon,DisconnectedIcon,SelectedIcon} from '../../icons/mapIcons';
import { useRef } from 'react';
const overAllzoom = 6;
const itemZoom = 13;
const useStyles = makeStyles(theme => ({
    container:{
        padding:0,
        margin:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        height:'100%',
        boxSizing:'border-box'

    },
    mapContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
      },

}));
const DEFAULT_VIEWPORT = {
    center: [39.839299, 63.701198],
    zoom: overAllzoom,
}
let markers = [];
let polyLines = [];
let centerPoint = null;
let minPoint = null;
let maxPoint = null;
let zoomLevel = null;
const constructObjects= (trackers,selectedTracker)=>{
    markers = [];
    polyLines = [];
    let trackerPoint = trackers.filter(e=>{return e.properties.lastKnownLattitude && e.visible;});        
    if(trackerPoint.length > 0){
        
        maxPoint = [trackerPoint[0].properties.lastKnownLattitude,trackerPoint[0].properties.lastKnownLongitude];
        minPoint = [trackerPoint[0].properties.lastKnownLattitude,trackerPoint[0].properties.lastKnownLongitude];
        
        trackerPoint.forEach(e => {
            let icon = null;
            if(selectedTracker && selectedTracker.id == e.id){
                icon = SelectedIcon;
            }else if(e.connected){
                icon = ConnectedIcon;
            }else{
                icon = DisconnectedIcon;
            }

            markers.push({key:e.id
                         ,position:[e.properties.lastKnownLattitude,e.properties.lastKnownLongitude]
                         ,content:e.serialNumber
                         ,icon:icon});
            if(maxPoint[0] < e.properties.lastKnownLattitude){
                maxPoint[0] = e.properties.lastKnownLattitude;
            }else if(minPoint[0] > e.properties.lastKnownLattitude){
                minPoint[0] = e.properties.lastKnownLattitude;
            }

            if(maxPoint[1] < e.properties.lastKnownLongitude){
                maxPoint[1] = e.properties.lastKnownLongitude;
                
            }else if(minPoint[1] > e.properties.lastKnownLongitude){
                minPoint[1] = e.properties.lastKnownLongitude;                
            }

            if(e.viewTrace){
                polyLines.push({key:e.serialNumber,positions:e.pathPoints,color:'green'});
            }
        });        
        if(selectedTracker && selectedTracker.properties.lastKnownLattitude){
            centerPoint = [selectedTracker.properties.lastKnownLattitude,selectedTracker.properties.lastKnownLongitude];
            zoomLevel = itemZoom;
        }else{
            centerPoint = [(maxPoint[0] + minPoint[0])/2,(maxPoint[1] + minPoint[1])/2];
            zoomLevel = overAllzoom;
        }

    }
    
}

const deductViewport = ()=>{
    const viewport = Object.assign({},DEFAULT_VIEWPORT);
    if(centerPoint){
        viewport.center = centerPoint;
        viewport.zoom = zoomLevel;

    }
    return viewport;
}


export default ({trackers,selectedTracker})=>{
    const classes = useStyles();    
    
    const mapRef = useRef();
    const myBounds = latLngBounds([23.749375, 43.865658],[39.839299, 63.701198]);
    constructObjects(trackers,selectedTracker);
    const currentViewport = deductViewport();
    return (
        <div className={classes.mapContainer}>
          <Map zoomControl={false} 
               reuseTiles={true} 
               maxBounds={myBounds}              
               ref={mapRef}
               onClick={()=>{
                   mapRef.current.leafletElement.setView(DEFAULT_VIEWPORT.center,DEFAULT_VIEWPORT.zoom);
                }}            
               center={currentViewport.center}
               zoom={currentViewport.zoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            
            <FeatureGroup>
              {markers.map(({ key, content, position, icon }) => (
                  <Marker key={key} position={position} icon={icon}>
                    <Popup>{content}</Popup>
                  </Marker>
              ))}
                {polyLines.map(({key,positions,color})=>(
                    <Polyline key={key} positions={positions} color={color}/>    
                ))}

            </FeatureGroup>
            
            
          </Map>
          <style>{`
            .leaflet-container {                
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
              }
          `}</style>
        </div>

    );
}
